import { Box } from "@chakra-ui/react";
import { navigate } from "gatsby";
import React, { useContext, useEffect } from "react";
import { TikTokController } from "../../services/tiktok";
import { ApiContext } from "../Root";
import { OpaliteButtonStandard } from "../shared/opalite_buttons";
import { COLORS_PRIMARY } from "../shared/opalite_colors";
import { OpaliteTextStandard, OpaliteTextTitle } from "../shared/opalite_text";

interface WhoAreYouProps {}

const WhoAreYou: React.FC<WhoAreYouProps> = () => {
  const api = useContext(ApiContext);
  const tikTokController = new TikTokController(api.client);

  useEffect(() => {
    tikTokController.createTikTokConnection();
  }, []);

  return (
    <Box height="100vh" backgroundColor={COLORS_PRIMARY.BACKGROUND}>
      <Box
        display="flex"
        flexDirection="column"
        position="fixed"
        bottom="2rem"
        width={{ base:"80%", md:"530px", lg:"530px" }}
        marginInline="10%"
      >
        <OpaliteTextTitle width="60%" marginBottom="1rem" fontWeight="bold">
          Who are you?
        </OpaliteTextTitle>
        <OpaliteTextStandard marginBottom="1rem">
          We enable high-performing influencers like you to turn hundreds of
          posts into cash-churning NFT's... all in the blink of an eye.
        </OpaliteTextStandard>
        <OpaliteButtonStandard
          onClick={() => navigate("all_nft")}
          width="60%"
          marginBottom="1rem"
        >
          Explore
        </OpaliteButtonStandard>
        <OpaliteButtonStandard
          onClick={() => navigate("create_account")}
          width="60%"
          marginBottom="1rem"
        >
          Mint
        </OpaliteButtonStandard>
      </Box>
    </Box>
  );
};

export default WhoAreYou;
