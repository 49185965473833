import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import Header from "../components/header/header";
import WhoAreYou from "../components/who_are_you/who_are_you";

function WhoAreYouPage() {
  return (
    <ChakraProvider>
      <Header />
      <WhoAreYou />
    </ChakraProvider>
  );
}

export default WhoAreYouPage;
